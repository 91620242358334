import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import largeLogo from "../images/InitialsLogoLarge.png";
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';

const NavbarComp = (props) => {
    useEffect(() => {
        const preloadImageList = [largeLogo]
        preloadImageList.forEach((image) => {
            new window.Image().src = image
        });
    }, []);

    const hoverEnter = (e) => {
        e.target.style.background = '#f0f0f0';
    }

    const hoverExit = (e) => {
        e.target.style.background = '#ffffff';
    }

    const logout = (e) => {
        e.preventDefault();
        props.logout();
      };

        return(
            <Navbar collapseOnSelect expand="md" id="mainNavbar" sticky="top" className={`py-0`} style={{backgroundColor: 'white'}}>
                <Navbar.Brand href="/" className="py-0">
                    <Image src={largeLogo} style={{height: "100%", width: "85px"}}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{marginRight: '8px'}}/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/" onMouseEnter={hoverEnter} onMouseLeave={hoverExit} className="text-center">Home</Nav.Link>
                        <NavDropdown.Divider className="my-0"/>
                        <Nav.Link href="/#contact" onMouseEnter={hoverEnter} onMouseLeave={hoverExit} className="text-center">Contact</Nav.Link>
                        <NavDropdown.Divider className="my-0"/>
                        <Nav.Link href="/resume" onMouseEnter={hoverEnter} onMouseLeave={hoverExit} className="text-center">Résumé</Nav.Link>
                        <NavDropdown.Divider className="my-0"/>
                        <Nav.Link href="/blog" onMouseEnter={hoverEnter} onMouseLeave={hoverExit} className="text-center">Personal Blog</Nav.Link>
                        {props.currentUser.isAuthenticated ? (
                            <Nav>
                                <NavDropdown.Divider className="my-0"/>
                                <Nav.Link href={`/users/${props.currentUser.user.id}/messages/new`} onMouseEnter={hoverEnter} onMouseLeave={hoverExit} className="text-center">New Message</Nav.Link>
                                <NavDropdown.Divider className="my-0"/>
                                <Nav.Link href="" onClick={logout} onMouseEnter={hoverEnter} onMouseLeave={hoverExit} className="text-center">Log Out</Nav.Link>
                            </Nav>
                        ) : (
                            <Nav></Nav>
                        )}
                        <NavDropdown.Divider className="my-0"/>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
}


function mapStateToProps(state) {
    return {
      currentUser: state.currentUser
    };
  }
  
export default connect(mapStateToProps, { logout })(NavbarComp);