import React from "react";
import Container from 'react-bootstrap/Container';

const Footer = () => {
    return (
        <footer className="py-3 footer" id="footer">
            <Container>
                <span className="text-muted">Erik Kenkel - {new Date().getFullYear()}</span>
            </Container>
        </footer>
    );
};

export default Footer;