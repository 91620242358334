import React, { Component } from "react";
import { connect } from "react-redux";
import { postNewMessage } from "../store/actions/messages";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import NavbarComp from "../components/NavbarComp";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class BlogForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      message: ""
    };
  }

  handleNewMessage = event => {
    event.preventDefault();
    console.log("title: " + this.state.title);
    console.log("message: " + this.state.message);
    this.props.postNewMessage(this.state.title, this.state.message);
    this.setState({ message: "", title: "" });
    this.props.history.push("/blog");
  };

  render() {
    return (
      <div>
        <NavbarComp/>
        <Container>
          <Row className="justify-content-md-center text-center">
            <Col md={6}>
              <h1 className="display-2 text-center mt-5"><b>New Post</b></h1>
              <Form onSubmit={this.handleNewMessage}>
                {this.props.errors.message && (
                  <Alert variant="danger">{this.props.errors.message}</Alert>
                )}
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                      type="text"
                      className="form-control"
                      value={this.state.title}
                      onChange={e => this.setState({ title: e.target.value })}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Message</Form.Label>
                  <Form.Control 
                    type="text"
                    className="form-control"
                    value={this.state.message}
                    onChange={e => this.setState({ message: e.target.value })}
                  />
                </Form.Group>
              <Button variant="primary" type="submit" size="md">Post</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.errors
  };
}

export default connect(mapStateToProps, { postNewMessage })(BlogForm);
