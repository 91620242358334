const particlesConfig = {
          background: {
              color: {
                  // value: "#000000",
              },
          },
          fpsLimit: 120,
          interactivity: {
              detect_on: "window",
              events: {
                  onClick: {
                      enable: true,
                      mode: "push",
                  },
                  onHover: {
                      enable: true,
                      mode: "repulse",
                  },
              },
              modes: {
                  push: {
                      quantity: 4,
                  },
                  repulse: {
                      distance: 200,
                      duration: 0.4,
                  },
              },
          },
          particles: {
              color: {
                  value: "#000000",
              },
              links: {
                  color: "#000000",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
              },
              move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                      default: "bounce",
                  },
                  random: false,
                  speed: 2,
                  straight: false,
              },
              number: {
                  density: {
                      enable: true,
                  },
                  limit: {
                      value: 200,
                  },
                  value: 80,

              },
              opacity: {
                  value: 0.5,
              },
              shape: {
                  type: "circle",
              },
              size: {
                  value: { min: 1, max: 5 },
              },
          },
    fullScreen: {
        enable: false,
        zIndex: 1
    },
          detectRetina: false,
      }
  
export default particlesConfig;