import ParticleConfig from "../config/particle-config"
import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";


export default function ParticleBackground() {
    const [init, setInit] = useState(false);

    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);


    if (init) {
        return (
            <Particles
                id="tsparticles"
                options={ParticleConfig}
            />
        );
    } else {
        return (
            <></>
        )
    }
}