import React from "react";
import { Switch, Route, withRouter, Redirect} from "react-router-dom";
import { connect } from "react-redux";
import Homepage from "../components/Homepage";
import AuthForm from "../components/AuthForm";
import Resume from '../components/Resume';
import { authUser } from "../store/actions/auth";
import {removeError} from "../store/actions/errors";
import BlogTimeline from "./BlogTimeline";
import withAuth from "./hocs/withAuth";
import BlogForm from "./BlogForm.js";
import "../styles/index.css";

const Main = props => {
    const { authUser, errors, removeError, currentUser } = props;
    return (
        <main className="main">
            <Switch>
                <Route exact path="/" component={Homepage}/>
                <Route exact path="/resume" component={Resume}/>
                <Route exact path="/blog" render={props => <BlogTimeline currentUser={currentUser} {...props} />} />
                <Route exact path="/blog/admin" render={props => {
                    return (
                        <AuthForm
                        removeError = {removeError}
                        errors={errors}
                        onAuth={authUser}
                        buttonText="Log in"
                        heading="ADMIN"
                        {...props}
                    />
                    );
                }}
                />
                <Route path="/users/:id/messages/new" component={withAuth(BlogForm)} />
                <Route path="/*">
                    <Redirect to="/"/>
                </Route>
            </Switch>
        </main>
    );
};
function mapStateToProps(state) {
    return {
        currentUser: state.currentUser,
        errors: state.errors
    };
  }
  
  export default withRouter(connect(mapStateToProps, { authUser, removeError })(Main));
