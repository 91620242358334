import React, { useEffect } from 'react';
import "../styles/homepage.css";
import ParticleBackground from "../components/ParticleBackground";
import Typewriter from "typewriter-effect";
import NavbarComp from "./NavbarComp";
import ME from "../images/me.jpg";
import file from "../documents/Erik_Kenkel_Resume.pdf";
import yelp1 from "../images/YelpCamp.jpg";
import yelp2 from "../images/YelpCamp2.jpg";
import birthDataScatter from "../images/BirthDataScatter.jpg";
import dashboard from "../images/Dashboard.jpg";
import letterAnalyze from "../images/LetterAnalyze.jpg";
import medianAge from "../images/MedianAge.jpg";
import methanScatter from "../images/MethanCO2Scatter.jpg";
import poliForce from "../images/PoliForcePull.jpg";
import dog1 from "../images/dogIdent1.jpg"
import dog2 from "../images/dogIdent2.jpg"
import dog3 from "../images/dogIdent3.jpg"
import {
  Carousel,
  Row,
  Col,
  Container,
  Image,
  Button,
  Card,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { mdiLinkedin, mdiGithub, mdiFileDocument, mdiEmail } from "@mdi/js";
import Icon from "@mdi/react";

const Homepage = () => {
  useEffect(() => {
    const preloadImageList = [ME, yelp1, yelp2, birthDataScatter, dashboard, letterAnalyze, medianAge, methanScatter, poliForce, dog1, dog2, dog3]
    preloadImageList.forEach((image) => {
      new window.Image().src = image
    });
  }, []);

  return (
    <div>
      <section
        className="hero d-flex justify-content-center align-items-center text-center"
        id="dashHeader"
      >
        <ParticleBackground />
        <div className="hero-content-area" style={{ zIndex: 0 }}>
          <Row>
            <h1>
              Hello, I'm <strong>Erik Kenkel</strong>,
            </h1>
          </Row>
          <Row className="justify-content-center">
            <h2 className="pa-0">
              <span>a</span>
              <Typewriter
                options={{
                  strings: [
                    " CSE graduate of Ohio State.",
                    " software developer.",
                    " vinyl collector.",
                    " Formula 1 enthusiast.",
                    " pianist.",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h2>
          </Row>
        </div>
      </section>
      <NavbarComp />
      <Container>
        <section
          id="about"
          className="d-flex align-items-center"
          style={{ minHeight: "90vh" }}
        >
          <div>
            <Row>
              <Col>
                <h2 className="px-0 mb-5 text-center">About Me</h2>
              </Col>
            </Row>
            <Row className="align-items-centers justify-content-center">
              <Col xs={8} sm={6} md={4} className="d-flex align-items-center">
                <Image src={ME} alt="" roundedCircle fluid />
              </Col>
              <Col
                sm={12}
                md={8}
                className="lead pt-2 text-center d-flex align-items-center"
              >
                <p className="my-0">
                  I am a graduate of The Ohio State University, where I studied computer science and engineering (CSE) with a specialization in artificial intelligence and a minor in business analytics. Currently, I am actively expanding my project portfolio as I pursue new opportunities in my field.
                </p>
              </Col>
            </Row>
          </div>
        </section>
        <section
          id="projects"
          className="d-flex align-items-center"
          style={{ minHeight: "90vh" }}
        >
          <Row className="justify-content-center text-center">
            <Col xs={12} className="pb-10">
              <h2 className="text-center mb-5">My Work</h2>
            </Col>
            <Col md={3} className="pt-3">
              <Card>
                <Carousel variant="dark" nextLabel="" prevLabel="" slide={false}>
                  <Carousel.Item>
                    <Image
                      className="d-block w-100 caroImage"
                      src={yelp1}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="d-block w-100 caroImage"
                      src={yelp2}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                </Carousel>
                <Card.Body className="d-flex flex-column">
                  <Card.Title>YelpCamp</Card.Title>
                  <Card.Text>
                    Worked through a tutorial to build a MERN Stack Website
                    that allows users to post reviews about campsites.
                  </Card.Text>
                  <Button
                    variant="outline-dark"
                    href="https://github.com/ekenkel/YelpCamp-2.0"
                    className="mt-auto"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Github
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="pt-3">
              <Card>
                <Carousel variant="dark" nextLabel="" prevLabel="" slide={false}>
                  <Carousel.Item>
                    <Image
                      className="d-block w-100 caroImage"
                      src={dashboard}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="d-block w-100 caroImage"
                      src={birthDataScatter}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="d-block w-100 caroImage"
                      src={medianAge}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="d-block w-100 caroImage"
                      src={letterAnalyze}
                      alt="Fourth slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="d-block w-100 caroImage"
                      src={methanScatter}
                      alt="Fifth slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                      className="d-block w-100 caroImage"
                      src={poliForce}
                      alt="Sixth slide"
                    />
                  </Carousel.Item>
                </Carousel>
                <Card.Body className="d-flex flex-column">
                  <Card.Title>D3 Projects</Card.Title>
                  <Card.Text>
                    Used JavaScript with D3.js library (Data Driven Documents) to provide data
                    visualization for collections of birth
                    rates, median ages, and CO2/methane emissions for
                    countries around the world. Created interactive
                    scatterplots, histograms, pie charts, and force graphs.
                  </Card.Text>

                  <Button
                    variant="outline-dark"
                    href="https://github.com/ekenkel/D3-Learning"
                    className="mt-auto"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Github
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="pt-3 ">
              <Card>
                <Carousel variant="dark" nextLabel="" prevLabel="" slide={false}>
                  <Carousel.Item>
                    <Image
                        className="d-block w-100 caroImage"
                        src={dog1}
                        alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                        className="d-block w-100 caroImage"
                        src={dog2}
                        alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <Image
                        className="d-block w-100 caroImage"
                        src={dog3}
                        alt="Third slide"
                    />
                  </Carousel.Item>
                </Carousel>
                <Card.Body className="d-flex flex-column">
                  <Card.Title>Dog Breed Identifier Neural Network Model</Card.Title>
                  <Card.Text>
                    Created a model using Python with the Fastai and Pytorch libraries that can recognize 146 breeds of dogs with 82% accuracy. Trained using the ConvNeXt-22k CNN.
                  </Card.Text>
                  <Button
                      variant="outline-primary"
                      href="https://huggingface.co/spaces/ekenkel/dog-identifier"
                      className="mt-auto"
                      target="_blank"
                      rel="noreferrer"
                  >
                    Open
                  </Button>
                  <Button
                      variant="outline-dark"
                      href="https://github.com/ekenkel/dog-identifier"
                      className="mt-2"
                      target="_blank"
                      rel="noreferrer"
                  >
                    Github
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
        <section
          id="contact"
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "30vh" }}
        >
          <div>
            <Row>
              <Col xs={12}>
                <h2 className="px-0 pb-10 text-center mb-5">Contact</h2>
              </Col>
              <Col xs={12} className="d-flex justify-content-center">
                <OverlayTrigger
                  key="linkedin"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-linkedin`}>
                      Visit my LinkedIn!
                    </Tooltip>
                  }
                >
                  <a
                    href="https://www.linkedin.com/in/erikkenkel/"
                    className="link-dark"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon path={mdiLinkedin} title="LinkedIn" size={2} />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  key="github"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-github`}>Visit my Github!</Tooltip>
                  }
                >
                  <a href="http://github.com/ekenkel" className="link-dark" target="_blank" rel="noreferrer">
                    <Icon path={mdiGithub} title="Github" size={2} />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  key="resume"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-resume`}>
                      View my Résumé!
                    </Tooltip>
                  }
                >
                  <a href={file} className="link-dark" target="_blank" rel="noreferrer">
                    <Icon path={mdiFileDocument} title="Resume" size={2} />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  key="email"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-resume`}>Send me an Email!</Tooltip>
                  }
                >
                  <a href="mailto:ekenkel7@gmail.com" className="link-dark">
                    <Icon path={mdiEmail} title="Email" size={2} />
                  </a>
                </OverlayTrigger>
              </Col>
            </Row>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Homepage;
