import React, { Component } from "react";
import { Container, Row, Col, Form, Alert, Button } from 'react-bootstrap';
import NavbarComp from './NavbarComp';

class AuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      username: "",
      password: "",
    };
  }

  handleSubmit = e => {
    //e.prevent... so the page doesn't refresh
    e.preventDefault();
    this.props.onAuth("login", this.state)
      .then(() => {
        this.props.history.push("/blog");
      })
      .catch(() => {

      });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { email, password } = this.state;
    const { buttonText, errors, history, removeError } = this.props;

    /* listens to any change in the route */
    history.listen(() => {
      /* if there is any change in the route then remove the error */
      removeError();
    })

    return (
      <div>
        <NavbarComp fixed="top"/>
        <Container>
          <Row className="justify-content-md-center text-center">
            {errors.message && (<Alert variant="danger">{errors.message}</Alert>)}
            <Col md={6}>
            <h1 className="display-2 text-center mt-5"><b>ADMIN</b></h1>
            <Form onSubmit={this.handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    autoComplete="off"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={this.handleChange}
                    value={email}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control 
                    autoComplete="off"
                    className="form-control"
                    id="password"
                    name="password"
                    onChange={this.handleChange}
                    type="password"
                    value={password}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" size="md">{buttonText}</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AuthForm;
