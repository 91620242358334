import React from "react";
import "../styles/resume.css";
import NavbarComp from "./NavbarComp";
import bigO from "../images/bigO.png";
import stX from "../images/stX.png";
import {
  Row,
  Col,
  Container,
  Image,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import Icon from "@mdi/react";
import resume from "../documents/Erik_Kenkel_Resume.pdf";
import { mdiFileDocument } from "@mdi/js";


class Resume extends React.Component {
  componentDidMount() {
    const preloadImageList = [bigO, stX]
    preloadImageList.forEach((image) => {
      new window.Image().src = image
    });
  }

  render() {
    return (
      <div>
        <NavbarComp fixed="top" />
        <Container>
          <h1 className="display-2 text-center mt-5">
            <b>Résumé</b>
          </h1>
          <section id="education">
            <h1 className="display-4 text-center">Education</h1>
            <Row className="align-items-center d-flex align-items-center mt-5">
              <Col sm={2} className="d-flex justify-content-center">
                <div style={{ maxWidth: "150px" }}>
                  <Image src={bigO} fluid></Image>
                </div>
              </Col>
              <Col xs={12} sm={10}>
                <Row className="justify-content-between text-center">
                  <Col xs={12} sm={6}>
                    <h2 className="lead" style={{ fontSize: "1.6rem" }}>
                      <b>The Ohio State University</b>
                    </h2>
                  </Col>
                  <Col xs={12} sm={6}>
                    <h3 className="lead">
                      <b>Graduated December 2023</b>
                    </h3>
                  </Col>
                  <Col xs={12} sm={6}>
                    <p className="text-muted">
                      BS, Computer Science & Engineering, Minor Business
                      Analytics
                    </p>
                  </Col>
                  <Col xs={12} sm={6}>
                    <p className="text-muted">GPA: 3.7</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="align-items-center mt-5">
              <Col sm={2} className="d-flex justify-content-center">
                <div style={{ maxWidth: "110px" }}>
                  <Image src={stX} fluid></Image>
                </div>
              </Col>
              <Col xs={12} sm={10}>
                <Row className="justify-content-between text-center">
                  <Col xs={12} sm={6}>
                    <h2 className="lead" style={{ fontSize: "1.6rem" }}>
                      <b>St. Xavier High School</b>
                    </h2>
                  </Col>
                  <Col xs={12} sm={6}>
                    <h3 className="lead">
                      <b>Graduated in 2019</b>
                    </h3>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
          <section id="employment">
            <h1 className="display-4 text-center mt-5">Employment</h1>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12} sm={6}>
                <h3 className="lead" style={{ fontSize: "1.6rem" }}>
                  <b>Microsoft</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="lead">
                  <b>May 2023 - August 2023</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <p className="text-muted">
                  <i>Software Engineer Intern</i>
                </p>
              </Col>
              <Col xs={12} sm={6}></Col>
              <Col xs={0} sm={1} md={2}></Col>
              <Col xs={12} sm={10} md={8}>
                <div className="resume-desc">
                  <ul className="pl-0 resume-list">
                    <li>
                      - Developed data collection scripts for sampling a high-volume data stream using a C# and SQL based language
                    </li>
                    <li>
                      -	Employed machine learning techniques in Python to construct predictive models for file actions based on attributes
                    </li>
                    <li>
                      -	Devised caching strategy algorithms by leveraging model-generated probabilities
                    </li>
                    <li>
                      -	Improved cache accuracy by ~5% over an existing implementation through iterative optimization of the models and caching approaches
                    </li>
                    <li>
                      -	Diagnosed and devised solutions for polymorphic-related bugs within a C# API
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={0} sm={1} md={2}></Col>
            </Row>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12} sm={6}>
                <h3 className="lead" style={{ fontSize: "1.6rem" }}>
                  <b>JPMorgan Chase & Co.</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="lead">
                  <b>June 2022 - August 2022</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <p className="text-muted">
                  <i>Software Engineer Intern</i>
                </p>
              </Col>
              <Col xs={12} sm={6}></Col>
              <Col xs={0} sm={1} md={2}></Col>
              <Col xs={12} sm={10} md={8}>
                <div className="resume-desc">
                  <ul className="pl-0 resume-list">
                    <li>
                      - Extracted functionality of a legacy web application into microservices using Java and SQL to increase scalability and reduce deployment time
                    </li>
                    <li>
                      -	Developed template and strategy patterns to populate API requests with corresponding key-value pairs
                    </li>
                    <li>
                      -	Integrated services by mapping object data and sending it via Kafka
                    </li>
                    <li>
                      -	Deployed changes to testing environments within internal PaaS cloud using Jenkins CI/CD pipeline
                    </li>
                    <li>
                      -	Spring Boot, Hibernate, MSSQL, JUnit, Agile Scrum
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={0} sm={1} md={2}></Col>
            </Row>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12} sm={6}>
                <h3 className="lead" style={{ fontSize: "1.6rem" }}>
                  <b>Great American Insurance Group</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="lead">
                  <b>May 2021 - April 2022</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <p className="text-muted">
                  <i>Software Engineer Intern</i>
                </p>
              </Col>
              <Col xs={12} sm={6}></Col>
              <Col xs={0} sm={1} md={2}></Col>
              <Col xs={12} sm={10} md={8}>
                <div className="resume-desc">
                  <ul className="pl-0 resume-list">
                    <li>
                      - Developed and deployed an updated in-house content
                      management system for claims/policies using Java and
                      JavaScript that services 1,000+ daily users
                    </li>
                    <li>
                      - Vuetify, Spring Boot, Hibernate, SQL, JUnit, Agile XP, TDD
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={0} sm={1} md={2}></Col>
            </Row>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12} sm={6}>
                <h3 className="lead" style={{ fontSize: "1.6rem" }}>
                  <b>Fifth Third Bank</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="lead">
                  <b>May 2020 - August 2020</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <p className="text-muted">
                  <i>Finance Intern</i>
                </p>
              </Col>
              <Col xs={12} sm={6}></Col>
              <Col xs={0} sm={1} md={2}></Col>
              <Col xs={12} sm={10} md={8}>
                <div className="resume-desc">
                  <ul className="pl-0 resume-list">
                    <li>
                      - Processed and verified borrower data in several stages
                      of loan origination and processing
                    </li>
                    <li>
                      - Analyzed borrower data to determine fees and payoffs for
                      500+ loans
                    </li>
                    <li>
                      - Assisted temporary employees in building a strong
                      understanding of the software used
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={0} sm={1} md={2}></Col>
            </Row>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12} sm={6}>
                <h3 className="lead" style={{ fontSize: "1.6rem" }}>
                  <b>LensCrafters</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="lead">
                  <b>January 2019 - March 2019</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <p className="text-muted">
                  <i>Optometric Technician</i>
                </p>
              </Col>
              <Col xs={12} sm={6}></Col>
              <Col xs={0} sm={1} md={2}></Col>
              <Col xs={12} sm={10} md={8}>
                <div className="resume-desc">
                  <ul className="pl-0 resume-list">
                    <li>
                      - Preliminary optometric examination of patients using the
                      non-contact tonometry, auto-refractor, and retinal imaging
                      machines
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={0} sm={1} md={2}></Col>
            </Row>
          </section>
          <section id="involvement">
            <h1 className="display-4 text-center mt-5">
              Leadership & Involvement
            </h1>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12} sm={6}>
                <h3 className="lead" style={{ fontSize: "1.6rem" }}>
                  <b>Matthew 25: Ministries </b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="lead">
                  <b>January 2016 - Present</b>
                </h3>
              </Col>
            </Row>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12} sm={6}>
                <h3 className="lead" style={{ fontSize: "1.6rem" }}>
                  <b>Pi Kappa Phi Fraternity</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="lead">
                  <b>September 2020 - December 2023</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <p className="text-muted">
                  <i>Assistant Chaplain / Active Member</i>
                </p>
              </Col>
              <Col xs={12} sm={6}></Col>
            </Row>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12} sm={6}>
                <h3 className="lead" style={{ fontSize: "1.6rem" }}>
                  <b>AI Club</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="lead">
                  <b>February 2020 - December 2023</b>
                </h3>
              </Col>
            </Row>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12} sm={6}>
                <h3 className="lead" style={{ fontSize: "1.6rem" }}>
                  <b>Big Data & Analytics Association</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="lead">
                  <b>February 2021 - December 2023</b>
                </h3>
              </Col>
            </Row>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12} sm={6}>
                <h3 className="lead" style={{ fontSize: "1.6rem" }}>
                  <b>Senior Development Chairmen Leader</b>
                </h3>
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="lead">
                  <b>August 2018 - May 2019</b>
                </h3>
              </Col>
            </Row>
          </section>
          <section id="skills">
            <h1 className="display-4 text-center mt-5">Skills</h1>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12}>
                <h3 className="lead">
                  <b>Java, Python, JavaScript, PyTorch, Spring Boot, MERN Stack, Node.js, C, C#, Ruby</b>
                </h3>
              </Col>
            </Row>
            <Row className="justify-content-between text-center mt-5">
              <Col xs={12}>
                <h3 className="lead">
                  <b>
                    IntelliJ IDEA, Eclipse, Visual Studio Code, SQLiteStudio, Microsoft SQL Server, Microsoft Excel
                  </b>
                </h3>
              </Col>
            </Row>
          </section>
          <section
            id="download"
            className="d-flex align-items-center justify-content-center"
            style={{ height: "30vh" }}
          >
            <div>
              <h1 className="display-4 text-center mt-5">View my Résumé</h1>
              <Row className="justify-content-between text-center mt-5">
                <Col xs={12} className="d-flex justify-content-center">
                  <OverlayTrigger
                    key="resume"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-resume`}>
                        View my Résumé!
                      </Tooltip>
                    }
                  >
                    <a href={resume} className="link-dark" target="_blank" rel="noreferrer">
                      <Icon path={mdiFileDocument} title="Resume" size={2} />
                    </a>
                  </OverlayTrigger>
                </Col>
              </Row>
            </div>
          </section>
        </Container>
      </div>
    );
  }
}

export default Resume;
