import axios from "axios";

export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    /* After user logs out */
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function apiCall(method, path, data) {
  // axios.defaults.headers.common['API-Key'] = process.env.REACT_APP_API_KEY
  return new Promise((resolve, reject) => {
    return axios({
      method: method.toLowerCase(),
      url: path,
      data,
    })
      .then(res => {
        return resolve(res.data);
      })
      .catch(err => {
        return reject(err.response.data.error);
      });
  });
}
