import React, { useEffect } from 'react';
import Moment from "react-moment";
import { Row, Col, Button } from 'react-bootstrap';

const BlogItem = props => {
  const { date, title, text, removeMessage, isCorrectUser, eventKey } = props;

  return (
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
            <Row className="justify-content-between align-items-center">
              <Col xs={'auto'}>
                <h3 className="lead">
                  {title}
                  <span>    -    </span>
                  <Moment format="MM/DD/YYYY">
                    {date}
                  </Moment>
                </h3>
              </Col>
            </Row>
          </button>
        </h2>
        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
             data-bs-parent="#accordionExample">
          <div className="accordion-body">
            {text}
          </div>
          {isCorrectUser && (
              <div className="accordion-body">
                <div className="d-grid gap-2">
                  <Button variant="outline-danger" size="md" onClick={removeMessage}>
                    Delete
                  </Button>
                </div>
              </div>
          )}
        </div>
      </div>
  );
}

export default BlogItem
