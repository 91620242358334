import React from "react";
import BlogList from "./BlogList";
import NavbarComp from "../components/NavbarComp";
import { Container, Row } from 'react-bootstrap';

const BlogTimeline = () => {
  return (
    <div>
      <NavbarComp fixed="top"/>
      <Container>
        <Row>
          <h1 className="display-1 text-center my-5"><b>Blog</b></h1>
          <BlogList />
        </Row>
      </Container>
    </div>
  );
};

export default BlogTimeline;
