import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMessages, removeMessage } from "../store/actions/messages";
import BlogItem from "../components/BlogItem";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

class BlogList extends Component {
  constructor(props) {
    super(props);
    this.state = {dispContent : <Spinner animation="border" />};
    this.getContent = this.getContent.bind(this);
  }

  getContent() {
    // Reverse comment the below to retrieve posts - Turned off to save money
    // this.setState({dispContent : <h1 className="display-6 text-center mt-5">No current posts. Please check back in later!</h1>})
    const { messages, removeMessage, currentUser } = this.props;
    if (messages.length > 0) {
      this.setState({
        dispContent : messages.map((m, i) => (
            <BlogItem
                key={m._id}
                date={m.createdAt}
                title={m.title}
                text={m.text}
                eventKey={i}
                removeMessage={removeMessage.bind(this, m.user._id, m._id)}
                isCorrectUser={currentUser === m.user._id}
            />
        ))
      });
    } else {
      this.setState({dispContent : <h1 className="display-6 text-center mt-5">No current posts. Please check back in later!</h1>})
    }
  }

  async componentDidMount() {
    // Uncomment below if you want to retrieve messages again
    await this.props.fetchMessages();
    this.getContent();
  }

  render() {
    return (
      <div>
        <Row className="justify-content-md-center text-center">
          <Col md={8}>
            <div className="accordion" id="accordionExample" >
              {this.state.dispContent}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    messages: state.messages,
    currentUser: state.currentUser.user.id
  };
}

export default connect(mapStateToProps, { fetchMessages, removeMessage })(BlogList);
